var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contracts"
  }, [_c('b-modal', {
    attrs: {
      "id": "contract-type-select-modal",
      "title": "Выберите тип договора",
      "hide-footer": "",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "contract-type-select"
  }, [_c('div', {
    staticClass: "contract-type-select-btn",
    on: {
      "click": function ($event) {
        return _vm.openDoc('buy');
      }
    }
  }, [_c('div', {
    staticClass: "btn-title"
  }, [_vm._v("Покупка")]), _c('div', [_vm._v(" Классический договор купли-продажи. В этой модели магазин приобретает товар и оплачивает его с отсрочкой ")])]), _c('div', {
    staticClass: "contract-type-select-btn",
    on: {
      "click": function ($event) {
        return _vm.openDoc('commission');
      }
    }
  }, [_c('div', {
    staticClass: "btn-title"
  }, [_vm._v("Комиссия")]), _c('div', [_vm._v(" В этой модели комиссионер передает магазину товар на продажу, а расчет срока оплаты выполняется относительно даты реализации ")])])])]), _c('div', {
    staticClass: "contract-block"
  }, [_c('div', {
    staticClass: "add-contract"
  }, [_c('b-button', {
    staticClass: "btn-add",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.addContract
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/plusContaract.svg",
      "alt": "add-contract"
    }
  }), _vm._v(" Добавить договор ")])], 1), _c('div', {
    staticClass: "existing-contracts"
  }, _vm._l(_vm.getTreaty, function (contract) {
    return _c('add-edit-contract', {
      key: contract.id,
      attrs: {
        "contract": contract,
        "opened": contract.opened
      },
      on: {
        "refetch": _vm.refetch
      }
    });
  }), 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }