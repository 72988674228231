var render = function render(){
  var _vm$item;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "double-menu-section commission"
  }, [_c('b-modal', {
    attrs: {
      "id": 'category-commission-modal' + ((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.id),
      "size": "lg",
      "scrollable": "",
      "centered": "",
      "title": "Назначение % комиссии"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('e-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": close
          }
        }, [_vm._v("Сохранить")]), _c('e-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": close
          }
        }, [_vm._v("Отменить")])], 1)];
      }
    }])
  }, [_c('tooltip'), _c('div', {
    staticClass: "category-table"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "field all"
  }, [_vm._v("Товарная группа / Наименование товара")]), _c('div', {
    staticClass: "field"
  }, [_vm._v("Комиссия, %")])]), _c('category-commission-item', {
    attrs: {
      "level": -1,
      "treaty": _vm.item.id
    }
  })], 1)], 1), _c('div', {
    staticClass: "title"
  }, [_vm._v("Комиссия")]), _c('div', {
    staticClass: "input-block order"
  }, [_c('label', {
    attrs: {
      "for": "input-order"
    }
  }, [_vm._v("Отсрочка, Дней")]), _c('e-button', {
    staticClass: "commission-btn",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.openModal
    }
  }, [_vm._v(" Назначить % комиссии ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }