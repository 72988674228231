<template>
  <div class="double-menu-section commission">
    <b-modal
      :id="'category-commission-modal' + item?.id"
      size="lg"
      scrollable
      centered
      title="Назначение % комиссии"
    >
      <tooltip />
      <div class="category-table">
        <div class="header">
          <div class="field all">Товарная группа / Наименование товара</div>
          <div class="field">Комиссия, %</div>
        </div>
        <category-commission-item
          :level="-1"
          :treaty="item.id"
        />
      </div>
      <template #modal-footer="{ close }">
        <div class="d-flex">
          <e-button
            variant="primary"
            class="mr-2"
            @click="close"
            >Сохранить</e-button
          >
          <e-button
            variant="outline-primary"
            @click="close"
            >Отменить</e-button
          >
        </div>
      </template>
    </b-modal>
    <div class="title">Комиссия</div>
    <div class="input-block order">
      <label for="input-order">Отсрочка, Дней</label>
      <e-button
        variant="outline-primary"
        class="commission-btn"
        @click="openModal"
      >
        Назначить % комиссии
      </e-button>
    </div>
  </div>
</template>

<script>
  import CategoryCommissionItem from '@/views/contractors/components/contract-form/CategoryCommissionItem.vue'
  import Tooltip from '@/views/contractors/components/contract-form/modal/Tooltip.vue'

  export default {
    name: 'Commission',
    components: { CategoryCommissionItem, Tooltip },
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        first: true,
        contract: {},
        regardingList: [
          {
            id: 'AFTER_FIX',
            name: 'Даты поставки'
          },
          {
            id: 'AFTER_SUPPLIER_DATE',
            name: 'Даты документа поставщика'
          },
          {
            id: 'AFTER_SELL',
            name: 'Даты реализации'
          }
        ]
      }
    },
    watch: {
      item() {
        const find = this.regardingList.find((item) => item.id === this.item.payment_term)
        this.contract = JSON.parse(
          JSON.stringify({
            ...this.item,
            payment_term: find
              ? [find]
              : [
                  {
                    id: 'AFTER_SELL',
                    name: 'Даты реализации'
                  }
                ]
          })
        )
      }
    },
    beforeMount() {},
    mounted() {
      this.$nextTick(() => (this.first = false))
    },
    methods: {
      inputDueDate() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      inputPaymentTerm() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      clearContract() {
        this.$forceUpdate()
        this.contract = JSON.parse(JSON.stringify(this.item))
      },
      openModal() {
        this.$bvModal.show('category-commission-modal' + this.item.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .commission-btn {
    height: 32px;
  }
  .double-menu-section {
    width: 50%;
  }
  ::v-deep .ms__dropdown-item.active {
    p {
      color: #00a3ff !important;
    }
  }
  .title {
    margin-top: 18px;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #bcbcbc;
    margin-bottom: 18px;
  }

  .input-block {
    max-width: 268px;
    margin-right: 16px;
    margin-bottom: 16px;
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #313131;
      margin: 0;
      margin-bottom: 4px;
    }
    .input-order {
      width: 80px;
    }
    .input-term-type {
      width: 250px !important;
    }
  }
  .horizontal-inputs {
    display: flex;
  }
  .category-table {
    border-radius: 2px;
    border-right: 1px solid var(--gray-gray-150, #e1e1e1);
    border-left: 1px solid var(--gray-gray-150, #e1e1e1);
    background: #fff;
    .header {
      display: flex;
      position: sticky;
      top: 0px;
      background: white;
      z-index: 2;
      border-top: 1px solid var(--gray-gray-150, #e1e1e1);
      border-radius: 2px 2px 0px 0px;
      .field {
        &.all {
          flex: 1;
        }

        padding: 12px;
        border-bottom: 1px solid var(--gray-gray-150, #e1e1e1);
        border-right: 1px solid var(--gray-gray-150, #e1e1e1);
        &:last-child {
          border-right: none;
        }
      }
    }
  }
</style>
