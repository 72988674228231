var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-section basic"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Основные данные")]), _c('div', {
    staticClass: "inputs"
  }, [_c('div', {
    staticClass: "input-block"
  }, [_c('label', {
    attrs: {
      "for": "input-name"
    }
  }, [_vm._v("Наименование договора")]), _c('e-input', {
    attrs: {
      "id": "input-name",
      "error": _vm.nameError,
      "error_text": "Поле не может быть пустым",
      "placeholder": "Наименование договора",
      "name": "input-name"
    },
    model: {
      value: _vm.contract.name,
      callback: function ($$v) {
        _vm.$set(_vm.contract, "name", $$v);
      },
      expression: "contract.name"
    }
  })], 1), _c('div', {
    staticClass: "input-block"
  }, [_c('label', {
    attrs: {
      "for": "input-number"
    }
  }, [_vm._v("Номер договора")]), _c('e-input', {
    attrs: {
      "id": "input-number",
      "error": _vm.numberError,
      "error_text": "Поле не может быть пустым",
      "placeholder": "Введите номер договора",
      "name": "input-number"
    },
    model: {
      value: _vm.contract.number,
      callback: function ($$v) {
        _vm.$set(_vm.contract, "number", $$v);
      },
      expression: "contract.number"
    }
  })], 1), _c('div', {
    staticClass: "input-block date"
  }, [_c('label', {
    attrs: {
      "for": "date"
    }
  }, [_vm._v("Дата договора")]), _c('date-picker', {
    staticClass: "elm-calendar",
    attrs: {
      "id": "date",
      "name": "date",
      "clearable": false,
      "placeholder": "",
      "value": new Date(_vm.contract.date),
      "format": "dd.MM.yyyy",
      "transfer": ""
    },
    on: {
      "on-change": _vm.setDate
    }
  })], 1), _c('div', {
    staticClass: "input-block file"
  }, [_c('label', {
    attrs: {
      "for": "input"
    }
  }, [_vm._v("Скан договора")]), _c('input', {
    ref: "input-upload",
    attrs: {
      "type": "file",
      "hidden": ""
    },
    on: {
      "input": _vm.setFile
    }
  }), !_vm.contract.file ? _c('b-button', {
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.clickUploadFile
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/plus-contract.svg",
      "alt": "add"
    }
  }), _vm._v(" Добавить файл ")]) : _vm._e(), _vm.contract.file ? _c('div', {
    staticClass: "file-block"
  }, [_c('p', [_vm._v(_vm._s(_vm.getFileName()))]), _c('img', {
    attrs: {
      "src": "/img/icons/close-contracts.svg",
      "alt": "delete file"
    },
    on: {
      "click": function ($event) {
        _vm.contract.file = null;
      }
    }
  })]) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }