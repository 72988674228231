<template>
  <div>
    <div
      v-for="category of categories"
      :key="category.id"
    >
      <div class="category">
        <div
          class="category-info"
          @click="open_category(category.id)"
        >
          <div :style="{ 'padding-left': 16 * (level + 2) + 'px' }">
            <img
              v-if="!category.last"
              src="/img/icons/menu_arrow_down.svg"
              :class="{ opened: is_opened_category(category.id) }"
              alt="Открыть"
            />
            <div
              v-else
              style="width: 12px"
            ></div>
          </div>

          <div class="name">
            {{ category.name }}
          </div>
        </div>
        <div class="category-input">
          <e-input
            class="my-input"
            :class="{ has_children: category.markup?.has_children }"
            placeholder="0"
            :value="category.markup?.value"
            @change="(val) => changeCommission(category.id, val)"
          />
        </div>
      </div>

      <category-commission-item
        v-if="is_opened_category(category.id) && !category.last"
        :parent="category.id"
        :level="level + 1"
        :treaty="treaty"
        class="category-commission-item"
        @refresh="refresh"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CategoryCommissionItem',
    props: {
      treaty: String,
      parent: String,
      level: Number
    },
    apollo: {
      Categories: {
        query: require('../../gql/Categories.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          const CATEGORIES = ['level_one', 'level_two', 'level_three', 'level_four']
          return {
            input: {
              [CATEGORIES[this.level]]: this.parent,
              level: this.level + 1
            },
            treaty: this.treaty
          }
        },
        result(result) {
          if (result) {
            const { data } = result
            this.categories = data.Categories
          }
        }
      }
    },
    data() {
      return {
        categories: [],
        opened_category: [],
        opened: false
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getTreaty: 'contractors/getTreaty'
      })
    },
    methods: {
      open_category(id) {
        if (this.is_opened_category(id)) {
          this.opened_category = this.opened_category.filter((el) => el !== id)
        } else {
          this.opened_category.push(id)
        }
      },
      is_opened_category(id) {
        return this.opened_category.some((el) => el === id)
      },
      changeCommission(id, value) {
        this.$apollo.mutate({
          mutation: require('../../../products/gql/setGroupMarkup.graphql'),
          variables: {
            input: {
              branch: this.currentBranch.id,
              category: id,
              treaty: this.treaty,
              value: +value / 100,
              type: 'commission'
            }
          }
        })
        this.$emit('refresh')
      },
      refresh() {
        this.$apollo.queries.Categories.refetch()
        this.$emit('refresh')
      }
    }
  }
</script>
<style scoped lang="scss">
  .category {
    cursor: pointer;
    padding: 8px;
    display: flex;
    border-bottom: 1px #e1e1e1 solid;
    &:hover {
      background: #f8f8f9;
    }

    img {
      transform: rotate(-90deg);

      &.opened {
        transform: rotate(0);
      }
    }

    .category-info {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .category-input {
      padding-left: 12px;
      display: flex;
      .my-input {
        width: 80px;
        &.has_children {
          :deep() {
            input {
              color: #ffa800;
            }
          }
        }
      }
    }

    .name {
      margin-left: 16px;
      color: #313131;
    }
  }
</style>
