<template>
  <div class="double-menu-section pay">
    <div class="title">Условия оплаты</div>
    <div class="horizontal-inputs">
      <div class="input-block order">
        <label for="input-order">Отсрочка, Дней</label>
        <e-input
          id="input-order"
          v-model="contract.payment_due_date"
          class="input-order"
          placeholder="Введите"
          type="number"
          name="input-order"
          @input="inputDueDate"
        />
      </div>
      <div class="input-block">
        <label for="">Относительно</label>
        <e-select
          v-model="contract.payment_term"
          :options="regardingList"
          placeholder="Выберите"
          selected_type="text"
          :serchable="false"
          class="input-term-type"
          @input="inputPaymentTerm"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pay',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        first: true,
        contract: {},
        regardingList: [
          {
            id: 'AFTER_FIX',
            name: 'Даты поставки'
          },
          {
            id: 'AFTER_SUPPLIER_DATE',
            name: 'Даты документа поставщика'
          },
          {
            id: 'AFTER_SELL',
            name: 'Даты реализации'
          }
        ]
      }
    },
    watch: {
      item() {
        const find = this.regardingList.find((item) => item.id === this.item.payment_term)
        this.contract = JSON.parse(
          JSON.stringify({
            ...this.item,
            payment_term: find
              ? [find]
              : [
                  {
                    id: 'AFTER_SELL',
                    name: 'Даты реализации'
                  }
                ]
          })
        )
      }
    },
    beforeMount() {},
    mounted() {
      this.$nextTick(() => (this.first = false))
    },
    methods: {
      inputDueDate() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      inputPaymentTerm() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      clearContract() {
        this.$forceUpdate()
        this.contract = JSON.parse(JSON.stringify(this.item))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .double-menu-section {
    width: 50%;
  }
  ::v-deep .ms__dropdown-item.active {
    p {
      color: #00a3ff !important;
    }
  }
  .title {
    margin-top: 18px;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #bcbcbc;
    margin-bottom: 18px;
  }

  .input-block {
    max-width: 268px;
    margin-right: 16px;
    margin-bottom: 16px;
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #313131;
      margin: 0;
      margin-bottom: 4px;
    }
    .input-order {
      width: 80px;
    }
    .input-term-type {
      width: 250px !important;
    }
  }
  .horizontal-inputs {
    display: flex;
  }
</style>
