<template>
  <div class="form-section basic">
    <div class="title">Основные данные</div>
    <div class="inputs">
      <div class="input-block">
        <label for="input-name">Наименование договора</label>
        <e-input
          id="input-name"
          v-model="contract.name"
          :error="nameError"
          error_text="Поле не может быть пустым"
          placeholder="Наименование договора"
          name="input-name"
        />
      </div>
      <div class="input-block">
        <label for="input-number">Номер договора</label>
        <e-input
          id="input-number"
          v-model="contract.number"
          :error="numberError"
          error_text="Поле не может быть пустым"
          placeholder="Введите номер договора"
          name="input-number"
        />
      </div>
      <div class="input-block date">
        <label for="date">Дата договора</label>
        <date-picker
          id="date"
          name="date"
          :clearable="false"
          class="elm-calendar"
          placeholder=""
          :value="new Date(contract.date)"
          format="dd.MM.yyyy"
          transfer
          @on-change="setDate"
        />
      </div>
      <div class="input-block file">
        <label for="input">Скан договора</label>
        <input
          ref="input-upload"
          type="file"
          hidden
          @input="setFile"
        />
        <b-button
          v-if="!contract.file"
          variant="light"
          @click="clickUploadFile"
        >
          <img
            src="/img/icons/plus-contract.svg"
            alt="add"
          />
          Добавить файл
        </b-button>
        <div
          v-if="contract.file"
          class="file-block"
        >
          <p>{{ getFileName() }}</p>
          <img
            src="/img/icons/close-contracts.svg"
            alt="delete file"
            @click="contract.file = null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Basic',
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        nameError: false,
        numberError: false,
        contract: {},
        first: true
      }
    },
    watch: {
      'contract.name'() {
        if (this.nameError) {
          this.nameError = false
        }
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      'contract.date'() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      'contract.file'() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      'contract.number'() {
        if (this.numberError) {
          this.numberError = false
        }
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      item() {
        this.contract = JSON.parse(JSON.stringify({ ...this.item, file: null }))
      }
    },
    mounted() {
      this.$nextTick(() => (this.first = false))
    },
    methods: {
      clearContract() {
        this.$forceUpdate()
        this.contract = JSON.parse(JSON.stringify({ ...this.item, file: null }))
      },
      getFileName() {
        if (this.contract.file.name.length > 10) {
          const arr = this.contract.file.name.split('.')
          arr[0] = arr[0].slice(0, 10)
          return arr[0] + '...' + arr[arr.length - 1]
        }
      },
      setFile(e) {
        this.contract.file = e.target.files[0]
      },
      setDate(date) {
        const arrDate = date.split('.')
        this.contract.date = new Date(new Date(+arrDate[2], +arrDate[1] - 1, +arrDate[0]).toJSON()).toJSON()
      },
      clickUploadFile() {
        this.$refs['input-upload'].click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep p.error_text {
    margin-bottom: 0;
  }

  ::v-deep() {
    .form-contract {
      .inputs {
        &.file {
          button {
            border: 1px dashed #bcbcbc;
            border-radius: 3px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            color: #bcbcbc;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
  .form-contract {
    margin-top: 22px;
    .form-section {
      padding: 18px 0;
      border-top: 1px solid #e1e1e1;
    }
    .title {
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #bcbcbc;
      margin-bottom: 18px;
    }
    .inputs {
      display: flex;
      flex-direction: row;
      .input-block {
        max-width: 268px;
        width: 100%;
        margin-right: 16px;
        &.date {
          max-width: fit-content;
          width: fit-content;
        }
        &.file {
          display: flex;
          flex-direction: column;
          width: 140px;
          button {
            border: 1px dashed #bcbcbc;
            border-radius: 3px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            color: #bcbcbc;
            display: flex;
            justify-content: center;
            img {
              margin-right: 12px;
            }
          }
          .file-block {
            display: flex;
            align-items: center;
            img {
              cursor: pointer;
              margin-left: 10px;
            }
          }
          p {
            display: flex;
            align-items: center;
            height: 32px;
            margin: 0;
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }
        .elm-calendar {
          width: 110px;
        }
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #313131;
          margin: 0;
          margin-bottom: 4px;
        }
      }
    }
  }
</style>
