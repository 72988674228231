var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "double-menu-section order"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Условия заказа")]), _c('div', {
    staticClass: "vertical-inputs"
  }, [_c('div', {
    staticClass: "input-block order"
  }, [_c('label', {
    attrs: {
      "for": "input-order"
    }
  }, [_vm._v("Мин. сумма заказа, ₽")]), _c('e-input', {
    attrs: {
      "id": "input-order",
      "type": "number",
      "placeholder": "Введите",
      "name": "input-order"
    },
    on: {
      "input": _vm.inputOrder
    },
    model: {
      value: _vm.contract.min_sum,
      callback: function ($$v) {
        _vm.$set(_vm.contract, "min_sum", $$v);
      },
      expression: "contract.min_sum"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }