<template>
  <div class="double-menu-section chart-shipment">
    <div class="title">График поставок</div>
    <div class="vertical-inputs">
      <div class="input-block">
        <label for="input-order">Дни недели</label>
        <e-select
          v-model="contract.delivery_days"
          multiple
          select_name="delivery_days"
          :options="weekdays"
          :max_selected_count="1"
          placeholder="Дни недели"
          @input="inputDeliveryDays"
        />
      </div>
      <div class="input-block">
        <label for="input-order">Недели месяца</label>
        <e-select
          v-model="contract.delivery_weeks"
          select_name="delivery_weeks"
          :max_selected_count="1"
          :options="weeks"
          placeholder="Недели месяца"
          multiple
          @input="inputDeliveryWeeks"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartShipment',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        first: true,
        contract: {},
        weekdays: [
          {
            id: 0,
            name: 'Понедельник'
          },
          {
            id: 1,
            name: 'Вторник'
          },
          {
            id: 2,
            name: 'Среда'
          },
          {
            id: 3,
            name: 'Четверг'
          },
          {
            id: 4,
            name: 'Пятница'
          },
          {
            id: 5,
            name: 'Суббота'
          },
          {
            id: 6,
            name: 'Воскресенье'
          }
        ],
        weeks: [
          {
            id: 0,
            name: 'Первая'
          },
          {
            id: 1,
            name: 'Вторая'
          },
          {
            id: 2,
            name: 'Третья'
          },
          {
            id: 3,
            name: 'Четвертая'
          }
        ]
      }
    },
    watch: {
      item() {
        this.contract = JSON.parse(
          JSON.stringify({
            ...this.item,
            delivery_days: this.item.delivery_days?.length
              ? this.weekdays.filter((obj) => this.item.delivery_days.includes(obj.id))
              : [],
            delivery_weeks: this.item.delivery_weeks?.length
              ? this.weeks.filter((obj) => this.item.delivery_weeks.includes(obj.id))
              : [...this.weeks]
          })
        )
      }
    },
    mounted() {
      this.$nextTick(() => (this.first = false))
    },
    methods: {
      inputDeliveryDays() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      inputDeliveryWeeks() {
        // if (!this.first) this.$emit('set_contract', this.contract)
        this.$emit('set_contract', this.contract)
      },
      clearContract() {
        this.$forceUpdate()
        this.contract = JSON.parse(JSON.stringify(this.item))
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep() {
    .input-block {
      max-width: 268px;
      width: 100%;
      margin-right: 16px;
      margin-bottom: 16px;

      &.date {
        max-width: fit-content;
        width: fit-content;
      }
      &.file {
        button {
          border: 1px dashed #bcbcbc;
          border-radius: 3px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 26px;
          color: #bcbcbc;
          display: flex;
          justify-content: center;
          img {
            margin-right: 12px;
          }
        }
      }

      .file-block {
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
          margin-left: 10px;
        }
      }
      p {
        display: flex;
        align-items: center;
        height: 32px;
        margin: 0;
        text-decoration: underline;
        text-underline-offset: 4px;
      }

      .elm-calendar {
        width: 110px;
      }
    }
  }
  .vertical-inputs {
    display: flex;
    flex-direction: column;
    .input-block {
      &.date {
        max-width: fit-content;
        width: fit-content;
      }
      &.file {
        display: flex;
        flex-direction: column;
        width: 140px;
      }
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #313131;
        margin: 0;
        margin-bottom: 4px;
      }
    }
  }
  .double-menu-section {
    max-width: 270px;
    width: 100%;
    .title {
      margin-top: 18px;
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #bcbcbc;
      margin-bottom: 18px;
    }
  }
</style>
