<template>
  <div class="form-section staff">
    <div class="title">Контакты сотрудников</div>
    <div class="inputs">
      <div class="input-block">
        <label for="">Юр. лицо магазина</label>
        <e-select
          id="entity-select"
          v-model="entity"
          :error="entityError"
          selected_type="text"
          :serchable="false"
          :options="entitiesList ?? []"
        />
        <p
          v-if="entityError"
          class="error-text"
        >
          Поле не может быть пустым
        </p>
      </div>
      <div class="input-block">
        <label for="">Магазин</label>
        <e-select
          v-model="branch"
          selected_type="text"
          placeholder="Все магазины"
          :serchable="false"
          :options="getBranchList ?? []"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Staff',
    props: {
      item: {
        type: Object
      }
    },
    data() {
      return {
        branchError: false,
        entityError: false,
        branch: [],
        entity: [],
        entitiesList: [],
        first: true
      }
    },
    apollo: {
      Entities: {
        query: require('../../../settings/gql/CompanyEntities.graphql'),
        result({ data }) {
          this.entitiesList = data.Entities
        }
      }
    },
    watch: {
      async branch() {
        this.$emit('set_contract', {
          ...this.item,
          entity: this.entity?.[0]?.id ?? null,
          branch: this.branch?.[0]?.id ?? null
        })
      },
      entity() {
        if (this.entityError) {
          this.entityError = false
        }
        this.$emit('set_contract', {
          ...this.item,
          entity: this.entity?.[0]?.id ?? null,
          branch: this.branch?.[0]?.id ?? null
        })
      },
      item() {
        this.entity = JSON.parse(JSON.stringify(this.item?.entity?.id ? [this.item?.entity] : []))
        this.branch = JSON.parse(JSON.stringify(this.item?.branch?.id ? [this.item?.branch] : []))
      }
    },
    mounted() {
      this.entity = JSON.parse(JSON.stringify(this.item?.entity?.id ? [this.item?.entity] : []))
      this.branch = JSON.parse(JSON.stringify(this.item?.branch?.id ? [this.item?.branch] : []))
    },
    methods: {
      clearContract() {
        this.$forceUpdate()
        this.entity = JSON.parse(JSON.stringify(this.item?.entity?.id ? [this.item?.entity] : []))
        this.branch = JSON.parse(JSON.stringify(this.item?.branch?.id ? [this.item?.branch] : []))
      },
      update() {}
    },
    computed: {
      ...mapState({
        getBranchList(state) {
          return [
            { id: null, name: 'Все магазины' },
            ...state.settings?.branch?.list.filter((el) => el.entities?.some((el) => el?.id === this.entity?.[0]?.id))
          ]
        }
      })
    }
  }
</script>

<style lang="scss" scoped>
  .form-contract {
    margin-top: 22px;
    .form-section {
      padding: 18px 0;
      border-top: 1px solid #e1e1e1;
    }
    .title {
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #bcbcbc;
      margin-bottom: 18px;
    }
    .inputs {
      display: flex;
      flex-direction: row;
      .input-block {
        &.date {
          max-width: fit-content;
          width: fit-content;
        }
        &.file {
          display: flex;
          flex-direction: column;
          width: 140px;
        }
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #313131;
          margin: 0;
          margin-bottom: 4px;
        }
      }
    }
  }
  .error-text {
    margin: 6px 0 0 0;
    color: #e53835;
  }
  ::v-deep .ms__dropdown-item.active {
    p {
      color: #00a3ff !important;
    }
  }

  ::v-deep() {
    .input-block {
      max-width: 268px;
      width: 100%;
      margin-right: 16px;
      &.date {
        max-width: fit-content;
        width: fit-content;
      }

      &.file {
        display: flex;
        flex-direction: column;
        width: 140px;
        button {
          border: 1px dashed #bcbcbc;
          border-radius: 3px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 26px;
          color: #bcbcbc;
          display: flex;
          justify-content: center;
          img {
            margin-right: 12px;
          }
        }
        .file-block {
          display: flex;
          align-items: center;
          img {
            cursor: pointer;
            margin-left: 10px;
          }
        }
        p {
          display: flex;
          align-items: center;
          height: 32px;
          margin: 0;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }

      .elm-calendar {
        width: 110px;
      }
    }
  }
</style>
