var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "double-menu-section pay"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Условия оплаты")]), _c('div', {
    staticClass: "horizontal-inputs"
  }, [_c('div', {
    staticClass: "input-block order"
  }, [_c('label', {
    attrs: {
      "for": "input-order"
    }
  }, [_vm._v("Отсрочка, Дней")]), _c('e-input', {
    staticClass: "input-order",
    attrs: {
      "id": "input-order",
      "placeholder": "Введите",
      "type": "number",
      "name": "input-order"
    },
    on: {
      "input": _vm.inputDueDate
    },
    model: {
      value: _vm.contract.payment_due_date,
      callback: function ($$v) {
        _vm.$set(_vm.contract, "payment_due_date", $$v);
      },
      expression: "contract.payment_due_date"
    }
  })], 1), _c('div', {
    staticClass: "input-block"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Относительно")]), _c('e-select', {
    staticClass: "input-term-type",
    attrs: {
      "options": _vm.regardingList,
      "placeholder": "Выберите",
      "selected_type": "text",
      "serchable": false
    },
    on: {
      "input": _vm.inputPaymentTerm
    },
    model: {
      value: _vm.contract.payment_term,
      callback: function ($$v) {
        _vm.$set(_vm.contract, "payment_term", $$v);
      },
      expression: "contract.payment_term"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }