<template>
  <div class="contracts">
    <b-modal
      id="contract-type-select-modal"
      title="Выберите тип договора"
      hide-footer
      centered
    >
      <div class="contract-type-select">
        <div
          class="contract-type-select-btn"
          @click="openDoc('buy')"
        >
          <div class="btn-title">Покупка</div>
          <div>
            Классический договор купли-продажи. В этой модели магазин приобретает товар и оплачивает его с отсрочкой
          </div>
        </div>
        <div
          class="contract-type-select-btn"
          @click="openDoc('commission')"
        >
          <div class="btn-title">Комиссия</div>
          <div>
            В этой модели комиссионер передает магазину товар на продажу, а расчет срока оплаты выполняется относительно
            даты реализации
          </div>
        </div>
      </div>
    </b-modal>
    <div class="contract-block">
      <div class="add-contract">
        <b-button
          class="btn-add"
          variant="outline-primary"
          @click="addContract"
        >
          <img
            src="/img/icons/plusContaract.svg"
            alt="add-contract"
          />
          Добавить договор
        </b-button>
      </div>
      <div class="existing-contracts">
        <add-edit-contract
          v-for="contract in getTreaty"
          :key="contract.id"
          :contract="contract"
          :opened="contract.opened"
          @refetch="refetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import FormContract from '@/views/contractors/components/FormContract'
  import AddEditContract from '@/views/contractors/components/AddEditContract'
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'Contract',
    components: { AddEditContract, FormContract },
    apollo: {
      Entity: {
        query: require('../gql/getEntity.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.$route.params.id
          }
        },
        result({ data }) {
          this.setTreaty(
            data?.Entity.supplier_treaty?.map((obj) => {
              const newObj = {
                ...obj,
                ...obj.delivery,
                ...obj.payments?.[0]
              }
              delete newObj.delivery
              delete newObj.payments
              return newObj
            }) ?? []
          )
          this.is_loading = false
        }
      }
    },
    data() {
      return {
        is_loading: true,
        open: false,
        type: null
      }
    },
    computed: {
      ...mapGetters({
        getCurrentSupplierId: 'contractors/getCurrentSupplierId',
        getTreaty: 'contractors/getTreaty'
      })
    },
    methods: {
      ...mapActions({
        setTreaty: 'contractors/setTreaty'
      }),
      refetch() {
        this.$apollo.queries.Entity.refetch()
      },
      setContracts() {},
      getFileName() {
        if (this.file.name.length > 10) {
          const arr = this.file.name.split('.')
          arr[0] = arr[0].slice(0, 10)
          return arr[0] + '...' + arr[arr.length - 1]
        }
      },
      addContract() {
        this.$bvModal.show('contract-type-select-modal')
        // this.open = true
      },
      async openDoc(type) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/CreateTreaty.graphql'),
          variables: {
            input: {
              supplier: this.getCurrentSupplierId,
              type: type,
              payment: {
                payment_term: type === 'commission' ? 'AFTER_SELL' : 'AFTER_FIX'
              }
            }
          }
        })
        this.setTreaty([
          {
            id: data.CreateTreaty.id,
            new: true,
            branch: null,
            entity: null,
            name: 'Новый договор',
            number: '',
            file: null,
            date: new Date(),
            address: '',
            delivery_days: [],
            delivery_weeks: [],
            order_days: [],
            order_weeks: [],
            min_sum: 0,
            payment_due_date: 0,
            payment_term: type === 'commission' ? 'AFTER_SELL' : 'AFTER_FIX',
            type: type,
            opened: true,
            is_active: true
          },
          ...this.getTreaty?.filter((el) => el.id)
        ])
        this.$bvModal.hide('contract-type-select-modal')
        this.type = type
      },
      clickUploadFile() {
        document.getElementById('input-upload').click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contract-type-select {
    display: flex;
    gap: 16px;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  .contract-type-select-btn {
    flex: 1;
    border-radius: 10px;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    background: var(--gray-white, #fff);
    padding: 12px;
    .btn-title {
      color: var(--text-primary-gray-700, #313131);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }
    &:hover {
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
      cursor: pointer;
    }
  }

  ::v-deep() {
    .existing-contracts {
      .contract {
        margin-bottom: 16px;
        width: 100%;
        padding: 22px;
        background-color: #fff;
        border: 1px solid #e1e1e1;
        border-radius: 6px;
        .visible-info {
          display: flex;
          justify-content: space-between;
          &__left {
            .category__name {
              font-family: 'Gilroy-Medium', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #313131;
            }
            img {
              margin-right: 12px;
              margin-left: 12px;
            }
            .category__type {
              color: var(--text-primary-gray-700, #313131);
            }
            .contract-info {
              display: flex;
              p {
                margin-bottom: 0;
                margin-right: 16px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #888888;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
          &__right {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .contracts {
      .save-btn {
        margin-top: -76px;
        float: right;
        button {
          width: 136px;
          justify-content: center;
        }
      }
    }
  }

  .contracts {
    .contract-block {
      .add-contract {
        .btn-add {
          margin-top: 16px;
          margin-bottom: 16px;
          padding: 22px;
          display: flex;
          justify-content: center;
          color: #00a3ff;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          border-radius: 6px;
          border: 1px solid #00a3ff;
          width: 100%;
          img {
            margin-right: 12px;
          }
          &:active {
            background: #eff6ff;
          }
        }
      }
    }
  }
</style>
