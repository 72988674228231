<template>
  <div class="form-contract">
    <basic
      ref="basic"
      :item="item"
      @set_contract="setBasic"
    />
    <staff
      ref="staff"
      :item="item"
      @set_contract="setStaff"
    />
    <div class="double-menu wrap">
      <pay
        ref="pay"
        :item="item"
        @set_contract="setPay"
      />
      <commission
        v-if="item.type === 'commission'"
        :item="item"
      />
      <order
        ref="order"
        :item="item"
        @set_contract="setOrder"
      />
    </div>
    <div
      class="double-menu"
      style="padding-bottom: 0"
    >
      <chart-orders
        ref="chartOrder"
        :item="item"
        @set_contract="setChartOrders"
      />
      <chart-shipment
        ref="chartShipment"
        :item="item"
        @set_contract="setChartShipment"
      />
    </div>
  </div>
</template>

<script>
  import Basic from '@/views/contractors/components/contract-form/Basic'
  import Staff from '@/views/contractors/components/contract-form/Staff'
  import Pay from '@/views/contractors/components/contract-form/Pay'
  import Order from '@/views/contractors/components/contract-form/Order'
  import ChartOrders from '@/views/contractors/components/contract-form/ChartOrders'
  import ChartShipment from '@/views/contractors/components/contract-form/ChartShipment'
  import Commission from '@/views/contractors/components/contract-form/Commission.vue'
  export default {
    name: 'FormContract',
    components: { Commission, ChartShipment, ChartOrders, Order, Pay, Staff, Basic },
    props: {
      item: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        currItem: {}
      }
    },
    watch: {
      item() {
        this.currItem = JSON.parse(JSON.stringify(this.item))
      }
    },
    mounted() {
      this.currItem = JSON.parse(JSON.stringify(this.item))
    },
    methods: {
      setBasic(data) {
        this.currItem.file = data.file
        this.currItem.number = data.number
        this.currItem.date = data.date
        this.currItem.name = data.name
        this.setContract()
      },
      setStaff(data) {
        this.currItem.branch = data.branch
        this.currItem.entity = data.entity
        this.setContract()
      },
      setPay(data) {
        this.currItem.payment_due_date = data.payment_due_date
        this.currItem.payment_term = data.payment_term?.[0]?.id ?? null
        this.setContract()
      },
      setOrder(data) {
        this.currItem.min_sum = data.min_sum
        this.setContract()
      },
      setChartOrders(data) {
        this.currItem.order_weeks = data.order_weeks
        this.currItem.order_days = data.order_days
        this.setContract()
      },
      setChartShipment(data) {
        this.currItem.delivery_weeks = data.delivery_weeks
        this.currItem.delivery_days = data.delivery_days
        this.setContract()
      },
      setContract() {
        this.$emit('set_contract', this.currItem)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .double-menu {
    display: flex;
    border-top: 1px solid #e1e1e1;
    padding-bottom: 18px;
    &.wrap {
      flex-wrap: wrap;
    }
  }

  .form-contract {
    margin-top: 22px;
  }
</style>
