<template>
  <div
    v-if="tooltip"
    class="info"
  >
    <div class="info__detail">
      <img
        src="/img/integrations/icon-info-blue.svg"
        alt=""
      />
      Укажите % комиссии на группы товаров, который будет отчислятся по факту реализованной продукции согласно условиям
      договора
      <div
        ref="hahaha"
        class="ml-2 d-flex align-items-center svg"
        @mouseover="changepos"
        @click="tooltip = false"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="9"
            cy="9"
            r="9"
            fill="#BBE6FF"
          />
          <path
            d="M5.81813 6.79713C5.54776 6.52677 5.54776 6.08842 5.81813 5.81806C6.08849 5.5477 6.52683 5.5477 6.7972 5.81806L12.1821 11.2029C12.4524 11.4733 12.4524 11.9117 12.1821 12.182C11.9117 12.4524 11.4734 12.4524 11.203 12.182L5.81813 6.79713Z"
            fill="#33B5FF"
          />
          <path
            d="M6.79659 12.182C6.52623 12.4523 6.08788 12.4523 5.81752 12.182C5.54716 11.9116 5.54716 11.4733 5.81752 11.2029L11.2024 5.81801C11.4728 5.54764 11.9111 5.54764 12.1815 5.81801C12.4518 6.08837 12.4518 6.52671 12.1815 6.79708L6.79659 12.182Z"
            fill="#33B5FF"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Tooltip',
    data() {
      return {
        tooltip: true
      }
    },
    methods: {
      changepos() {
        const height = this.$refs.hahaha.parentNode.parentNode.parentNode.clientHeight
        const width = this.$refs.hahaha.parentNode.parentNode.parentNode.clientWidth

        const y = Math.random() * (height + 1)
        const x = Math.random() * (width + 1)
        this.$refs.hahaha.style.position = 'absolute'
        this.$refs.hahaha.style.top = y + 'px'
        this.$refs.hahaha.style.right = x + 'px'
      }
    }
  }
</script>
<style scoped lang="scss">
  .svg {
    top: 17px;
    right: 5px;
    transition: all 1s ease;
    z-index: 100;
  }
  .info {
    position: relative;
    background-color: #fff;
    padding-bottom: 22px;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    &__detail {
      background: #eef9ff;
      border-radius: 4px;
      margin-top: 22px;
      border-left: 3px solid #99daff;
      padding: 15px;
      color: #313131;
      display: flex;
      img {
        margin-right: 15px;
      }
    }
  }
</style>
