var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tooltip ? _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "info__detail"
  }, [_c('img', {
    attrs: {
      "src": "/img/integrations/icon-info-blue.svg",
      "alt": ""
    }
  }), _vm._v(" Укажите % комиссии на группы товаров, который будет отчислятся по факту реализованной продукции согласно условиям договора "), _c('div', {
    ref: "hahaha",
    staticClass: "ml-2 d-flex align-items-center svg",
    on: {
      "mouseover": _vm.changepos,
      "click": function ($event) {
        _vm.tooltip = false;
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "9",
      "cy": "9",
      "r": "9",
      "fill": "#BBE6FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M5.81813 6.79713C5.54776 6.52677 5.54776 6.08842 5.81813 5.81806C6.08849 5.5477 6.52683 5.5477 6.7972 5.81806L12.1821 11.2029C12.4524 11.4733 12.4524 11.9117 12.1821 12.182C11.9117 12.4524 11.4734 12.4524 11.203 12.182L5.81813 6.79713Z",
      "fill": "#33B5FF"
    }
  }), _c('path', {
    attrs: {
      "d": "M6.79659 12.182C6.52623 12.4523 6.08788 12.4523 5.81752 12.182C5.54716 11.9116 5.54716 11.4733 5.81752 11.2029L11.2024 5.81801C11.4728 5.54764 11.9111 5.54764 12.1815 5.81801C12.4518 6.08837 12.4518 6.52671 12.1815 6.79708L6.79659 12.182Z",
      "fill": "#33B5FF"
    }
  })])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }