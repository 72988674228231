var render = function render(){
  var _vm$entitiesList, _vm$getBranchList;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-section staff"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Контакты сотрудников")]), _c('div', {
    staticClass: "inputs"
  }, [_c('div', {
    staticClass: "input-block"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Юр. лицо магазина")]), _c('e-select', {
    attrs: {
      "id": "entity-select",
      "error": _vm.entityError,
      "selected_type": "text",
      "serchable": false,
      "options": (_vm$entitiesList = _vm.entitiesList) !== null && _vm$entitiesList !== void 0 ? _vm$entitiesList : []
    },
    model: {
      value: _vm.entity,
      callback: function ($$v) {
        _vm.entity = $$v;
      },
      expression: "entity"
    }
  }), _vm.entityError ? _c('p', {
    staticClass: "error-text"
  }, [_vm._v(" Поле не может быть пустым ")]) : _vm._e()], 1), _c('div', {
    staticClass: "input-block"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Магазин")]), _c('e-select', {
    attrs: {
      "selected_type": "text",
      "placeholder": "Все магазины",
      "serchable": false,
      "options": (_vm$getBranchList = _vm.getBranchList) !== null && _vm$getBranchList !== void 0 ? _vm$getBranchList : []
    },
    model: {
      value: _vm.branch,
      callback: function ($$v) {
        _vm.branch = $$v;
      },
      expression: "branch"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }