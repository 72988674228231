<template>
  <div class="d-flex">
    <b-button
      variant="outline-primary"
      class="mr-2"
      @click="back"
    >
      Отмена
    </b-button>
    <b-button
      variant="primary"
      @click="next"
    >
      {{ next_btn_text }}
    </b-button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'TabControlButton',
    props: {
      tab_index: {
        type: Number,
        default: () => 0
      }
    },
    data() {
      return {}
    },

    methods: {
      back() {
        this.$router.back()
      },
      next() {
        this.$emit('next')
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      next_btn_text() {
        return this.tab_index !== 2 ? 'Далее' : 'Подписать и отправить'
      }
    }
  }
</script>

<style scoped lang="scss"></style>
