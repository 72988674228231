<template>
  <div class="double-menu-section order">
    <div class="title">Условия заказа</div>
    <div class="vertical-inputs">
      <div class="input-block order">
        <label for="input-order">Мин. сумма заказа, ₽</label>
        <e-input
          id="input-order"
          v-model="contract.min_sum"
          type="number"
          placeholder="Введите"
          name="input-order"
          @input="inputOrder"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Order',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        first: true,
        contract: {}
      }
    },
    watch: {
      item() {
        this.contract = JSON.parse(JSON.stringify(this.item))
      }
    },
    mounted() {
      this.$nextTick(() => (this.first = false))
    },
    methods: {
      inputOrder() {
        if (!this.first) this.$emit('set_contract', this.contract)
      },
      clearContract() {
        this.$forceUpdate()
        this.contract = JSON.parse(JSON.stringify(this.item))
      }
    }
  }
</script>

<style lang="scss" scoped>
  .double-menu {
    display: flex;
    border-top: 1px solid #e1e1e1;
    padding-bottom: 18px;
    .vertical-inputs {
      display: flex;
      flex-direction: column;
      .input-block {
        &.date {
          max-width: fit-content;
          width: fit-content;
        }
        &.file {
          display: flex;
          flex-direction: column;
          width: 140px;
        }
        label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #313131;
          margin: 0;
          margin-bottom: 4px;
        }
      }
    }
    .double-menu-section {
      &:first-child {
        margin-right: 150px;
      }
      max-width: 270px;
      width: 100%;
      .title {
        margin-top: 18px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #bcbcbc;
        margin-bottom: 18px;
      }
    }
  }

  ::v-deep() {
    .input-block {
      max-width: 268px;
      width: 100%;
      margin-right: 16px;
      margin-bottom: 16px;

      &.date {
        max-width: fit-content;
        width: fit-content;
      }

      &.file {
        display: flex;
        flex-direction: column;
        width: 140px;
        button {
          border: 1px dashed #bcbcbc;
          border-radius: 3px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 26px;
          color: #bcbcbc;
          display: flex;
          justify-content: center;
          img {
            margin-right: 12px;
          }
        }
        .file-block {
          display: flex;
          align-items: center;
          img {
            cursor: pointer;
            margin-left: 10px;
          }
        }
        p {
          display: flex;
          align-items: center;
          height: 32px;
          margin: 0;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }

      .elm-calendar {
        width: 110px;
      }
    }
  }
</style>
