var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-contract"
  }, [_c('basic', {
    ref: "basic",
    attrs: {
      "item": _vm.item
    },
    on: {
      "set_contract": _vm.setBasic
    }
  }), _c('staff', {
    ref: "staff",
    attrs: {
      "item": _vm.item
    },
    on: {
      "set_contract": _vm.setStaff
    }
  }), _c('div', {
    staticClass: "double-menu wrap"
  }, [_c('pay', {
    ref: "pay",
    attrs: {
      "item": _vm.item
    },
    on: {
      "set_contract": _vm.setPay
    }
  }), _vm.item.type === 'commission' ? _c('commission', {
    attrs: {
      "item": _vm.item
    }
  }) : _vm._e(), _c('order', {
    ref: "order",
    attrs: {
      "item": _vm.item
    },
    on: {
      "set_contract": _vm.setOrder
    }
  })], 1), _c('div', {
    staticClass: "double-menu",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c('chart-orders', {
    ref: "chartOrder",
    attrs: {
      "item": _vm.item
    },
    on: {
      "set_contract": _vm.setChartOrders
    }
  }), _c('chart-shipment', {
    ref: "chartShipment",
    attrs: {
      "item": _vm.item
    },
    on: {
      "set_contract": _vm.setChartShipment
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }