var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Отмена ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" " + _vm._s(_vm.next_btn_text) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }