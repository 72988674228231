var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "page-contractors"
  }, [_c('div', {
    staticClass: "wrapper__inner-widthlimit"
  }, [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "font-weight-bold"
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Основные параметры"
    }
  }, [_c('main-parameters', {
    ref: "main_form",
    attrs: {
      "is_edit": _vm.isEdit,
      "current_entity_data": _vm.currentEntityData
    },
    on: {
      "disabled_btn": _vm.handler_disable_next_btn
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Договоры"
    }
  }, [_c('contract')], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-cancel",
      "hide-footer": "",
      "title": "Отменить"
    }
  }, [_c('div', {
    staticClass: "center"
  }, [_vm._v("Все введённые данные будут утеряны. Продолжить?")]), _c('div', {
    staticClass: "modal-footer mt-4"
  }, [_c('b-button', {
    staticClass: "center",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")]), _c('b-button', {
    staticClass: "center",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.go_back
    }
  }, [_vm._v(" Продолжить ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }