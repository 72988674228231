<template>
  <section class="page-contractors">
    <div class="wrapper__inner-widthlimit">
      <b-tabs
        v-model="tabIndex"
        active-nav-item-class="font-weight-bold"
      >
        <b-tab title="Основные параметры">
          <main-parameters
            ref="main_form"
            :is_edit="isEdit"
            :current_entity_data="currentEntityData"
            @disabled_btn="handler_disable_next_btn"
          />
        </b-tab>
        <b-tab title="Договоры"><contract /></b-tab>
      </b-tabs>
    </div>
    <b-modal
      id="modal-cancel"
      hide-footer
      title="Отменить"
    >
      <div class="center">Все введённые данные будут утеряны. Продолжить?</div>
      <div class="modal-footer mt-4">
        <b-button
          class="center"
          variant="outline-primary"
          @click="hideModal"
        >
          Отмена
        </b-button>
        <b-button
          class="center"
          variant="primary"
          @click="go_back"
        >
          Продолжить
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
  import * as R from 'ramda'
  import MainParameters from '../components/MainParameters'
  import { mapActions, mapGetters } from 'vuex'
  import TabControlButton from '@/views/analytics/pages/TabControlButton'
  import Contract from '@/views/contractors/pages/Contract'

  export default {
    components: {
      Contract,
      TabControlButton,
      MainParameters
    },

    data: () => ({
      tabs: ['main', 'contract'],
      tabIndex: null,
      entity_data: false,
      disabled_tab2: true,
      disabled_tab3: false,
      disabledBtnNext: true,
      page_title: null
    }),

    computed: {
      ...mapGetters({
        currentEntityData: 'contractors/getCurrentEntityData',
        currentTab: 'contractors/getCurrentTab',
        currentEntityId: 'contractors/getCurrentEntityId',
        currentSupplier: 'contractors/getCurrentSupplier',
        currentSupplierId: 'contractors/getSupplierId',
        currentBranch: 'settings/getCurrentBranch',
        persons: 'contractors/getPersons',
        banksList: 'contractors/getBanks',
        isEdit: 'contractors/getSupplierIsEdit'
      })
    },

    watch: {
      currentEntityId(newVal, oldVal) {
        const data = {
          id: this.currentEntityId,
          name: this.currentEntityData.fullname
        }
        if (newVal !== oldVal) this.create_supplier(data)
      },

      tabIndex(newVal, oldVal) {
        let query = { ...this.$route.query }
        query.source = this.tabs[newVal]
        this.$router.replace({ query: query }).catch((er) => er)

        if ((newVal === 1 && oldVal === 0) || (newVal === 2 && oldVal === 1)) return (this.disabledBtnNext = false)

        this.set_current_tab(newVal)
      }
    },

    created() {
      this.checkIsEditItem()

      // if (this.currentEntityId) {
      //   if (this.currentTab === 2) {
      //     this.disabled_tab2 = false
      //     this.disabled_tab3 = false
      //     this.disabledBtnNext = false
      //   }
      // }
      //
      // if (this.currentTab === 1) this.disabled_tab2 = false
    },

    beforeMount() {
      this.setBreadcrumbs({ title: this.page_title, is_go_back: true })
      this.tabIndex = this.tabs.findIndex((tab) => tab === this.$route.query.source)
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },

    methods: {
      ...mapActions({
        create_entity: 'contractors/createEntity',
        create_supplier: 'contractors/createSupplier',
        create_contract: 'contractors/CreateContract',
        set_current_tab: 'contractors/SetCurrentTab',
        get_supplier: 'contractors/getSupplier',
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),

      checkIsEditItem() {
        if (this.currentSupplier.is_edit) {
          this.page_title = this.currentSupplier.title
          this.disabled_tab2 = false
          this.disabled_tab3 = false
          this.get_supplier(this.currentSupplier.supplier_id)
        }
      },

      open_modal() {
        this.$bvModal.show('modal-cancel')
      },

      hideModal() {
        this.$bvModal.hide('modal-cancel')
      },

      go_back() {
        this.$router.push({ name: 'contractors' })
      },

      save_supplier() {
        if (this.tabIndex === 0) {
          if (this.currentEntityId) {
            this.disabled_tab2 = false
            this.tabIndex++
            return this.set_current_tab(1)
          }

          const data = { ...this.$refs.main_form.entity_data }
          this.disabled_tab2 = false
          this.create_entity(data)
          this.set_current_tab(1)
        } else if (this.tabIndex === 1) {
          if (this.banksList.length > 0) {
            this.disabled_tab3 = false
            this.tabIndex++
            this.set_current_tab(2)
          }
        } else if (this.tabIndex === 2) {
          const getPersonId = R.pluck('id')
          const params = {
            ...this.$refs.conditions.contract,
            supplier: this.currentSupplierId,
            person: getPersonId(this.persons)
          }
          params.delivery_schedule.supplier = this.currentSupplierId
          params.delivery_schedule.branch = this.currentBranch.id

          this.create_contract(params)
        }
      },

      handler_disable_next_btn(isDisabled) {
        this.disabledBtnNext = isDisabled
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .nav-item {
      width: 220px !important;
    }

    .tab-height-limit {
      height: 100%;
      overflow: auto;
    }

    .b-btns {
      justify-content: flex-end;

      button {
        height: 42px;
        margin-left: 15px;
      }
    }

    .modal-footer {
      button {
        width: 40%;
        justify-content: center;
      }
    }
  }

  section.page-contractors {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0px;

    .wrapper__inner-widthlimit {
      position: relative;
      z-index: 9;
      height: 100%;
      overflow: visible;
      display: flex;
      flex-direction: column;
    }

    .tab-height-limit {
      height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    #modal-cancel {
      .modal-footer {
        padding-bottom: 0;
        justify-content: center;
      }
    }
  }
</style>
