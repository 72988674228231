var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.categories, function (category) {
    var _category$markup, _category$markup2;

    return _c('div', {
      key: category.id
    }, [_c('div', {
      staticClass: "category"
    }, [_c('div', {
      staticClass: "category-info",
      on: {
        "click": function ($event) {
          return _vm.open_category(category.id);
        }
      }
    }, [_c('div', {
      style: {
        'padding-left': 16 * (_vm.level + 2) + 'px'
      }
    }, [!category.last ? _c('img', {
      class: {
        opened: _vm.is_opened_category(category.id)
      },
      attrs: {
        "src": "/img/icons/menu_arrow_down.svg",
        "alt": "Открыть"
      }
    }) : _c('div', {
      staticStyle: {
        "width": "12px"
      }
    })]), _c('div', {
      staticClass: "name"
    }, [_vm._v(" " + _vm._s(category.name) + " ")])]), _c('div', {
      staticClass: "category-input"
    }, [_c('e-input', {
      staticClass: "my-input",
      class: {
        has_children: (_category$markup = category.markup) === null || _category$markup === void 0 ? void 0 : _category$markup.has_children
      },
      attrs: {
        "placeholder": "0",
        "value": (_category$markup2 = category.markup) === null || _category$markup2 === void 0 ? void 0 : _category$markup2.value
      },
      on: {
        "change": function (val) {
          return _vm.changeCommission(category.id, val);
        }
      }
    })], 1)]), _vm.is_opened_category(category.id) && !category.last ? _c('category-commission-item', {
      staticClass: "category-commission-item",
      attrs: {
        "parent": category.id,
        "level": _vm.level + 1,
        "treaty": _vm.treaty
      },
      on: {
        "refresh": _vm.refresh
      }
    }) : _vm._e()], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }