<template>
  <div class="contract">
    <div class="visible-info">
      <div class="visible-info__left">
        <div class="d-flex align-items-center">
          <b class="category__name">{{ newItem.name }}</b>
          <img
            src="/img/icons/delimiter.svg"
            alt=""
          />
          <div class="category__type">Тип: {{ mappingType[newItem.type] }}</div>
          <img
            src="/img/icons/delimiter.svg"
            alt=""
          />
          <e-switch
            active_color="#00CB91"
            :value="newItem.is_active ? 'all' : 'off'"
            @input="(val) => (val === 'all' ? (newItem.is_active = true) : (newItem.is_active = false))"
          />
        </div>
        <div class="contract-info">
          <p>№{{ newItem.number }}</p>
          <p>{{ new Date(newItem.date).toLocaleDateString() }}</p>
          <p>{{ newItem.address }}</p>
        </div>
      </div>
      <div class="visible-info__right">
        <b-dropdown
          class="dropdown-contract"
          no-caret
        >
          <template #button-content>
            <img
              class="dots"
              src="/img/icons/Dots.svg"
              alt="open-contract"
            />
          </template>
          <!--            <b-dropdown-item @click="copyItem(contract)">-->
          <!--              <div>Дублировать</div>-->
          <!--            </b-dropdown-item>-->
          <b-dropdown-item @click="deleteContract(newItem)">
            <div class="delete-button">Удалить</div>
          </b-dropdown-item>
        </b-dropdown>
        <img
          src="/img/icons/arrow-contract.svg"
          alt="open-contract"
          :class="{ rotate: open }"
          @click="open = !open"
        />
      </div>
    </div>
    <b-collapse
      :id="`contract-info${newItem.id}`"
      v-model="open"
    >
      <form-contract
        :ref="`form${newItem.id}`"
        :item="newItem"
        @set_contract="setContract"
      />
      <b-button
        variant="primary"
        @click="updateContract(newItem)"
      >
        Сохранить
      </b-button>
      <b-button
        v-b-toggle="`contract-info${newItem.id}`"
        style="margin-left: 16px"
        variant="light"
        @click="cancellationUpdate(newItem.id)"
      >
        Отмена
      </b-button>
    </b-collapse>
  </div>
</template>

<script>
  import FormContract from '@/views/contractors/components/FormContract'
  import { mapGetters } from 'vuex'
  import ESwitch from '@/views/settings/components/access_rights/ESwitch.vue'
  export default {
    name: 'AddEditContract',
    components: { ESwitch, FormContract },
    props: {
      contract: {
        type: Object,
        default: () => {}
      },
      type: String,
      new_contract: {
        type: Boolean,
        default: false
      },
      opened: Boolean
    },
    data() {
      return {
        open: this.opened || false,
        updateItem: {},
        newItem: {
          id: null,
          new: true,
          branch: null,
          entity: null,
          name: 'Новый договор',
          number: '',
          file: null,
          date: new Date(),
          address: '',
          delivery_days: [],
          delivery_weeks: [],
          order_days: [],
          order_weeks: [],
          min_sum: 0,
          payment_due_date: 0,
          payment_term: 'AFTER_FIX',
          type: null,
          is_active: true
        },
        mappingType: {
          buy: 'Покупка',
          commission: 'Комиссия'
        }
      }
    },
    watch: {
      contract() {
        this.newItem = JSON.parse(JSON.stringify(this.contract))
      }
    },
    mounted() {
      this.newItem = JSON.parse(JSON.stringify(this.contract))
    },
    computed: {
      ...mapGetters({
        getCurrentSupplierId: 'contractors/getCurrentSupplierId',
        getTreaty: 'contractors/getTreaty'
      })
    },
    methods: {
      async deleteContract(contract) {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/DeleteTreaty.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              ids: [contract.id]
            }
          })
          this.$noty.show(`Договор ${contract.name} успешно удален`)
          this.$emit('refetch')
        } catch (e) {
          this.$noty.error('Произошла ошибка при удалении')
        }
      },
      copyItem(doc) {
        const contract = Object.assign({}, doc)
        contract.name = contract.name + ' (копия)'
        this.newItem = contract
        this.createContract(true)
      },
      async createContract(isCopy) {
        try {
          if (!isCopy) {
            if (!this.newItem.name && !this.newItem.number && !this.newItem.branch) {
              this.$refs[`form_new`].$refs.basic.numberError = true
              this.$refs[`form_new`].$refs.basic.nameError = true
              this.$refs[`form_new`].$refs.staff.branchError = true
              return this.$noty.error('Заполните все необходимые поля')
            }
            if (!this.newItem.name && !this.newItem.number) {
              this.$refs[`form_new`].$refs.basic.numberError = true
              this.$refs[`form_new`].$refs.basic.nameError = true
              return this.$noty.error('Необходимо ввести наименование и номер договора')
            }
            if (!this.newItem.name) {
              this.$refs[`form_new`].$refs.basic.nameError = true
              return this.$noty.error('Необходимо ввести наименование договора')
            }
            if (!this.newItem.number) {
              this.$refs[`form_new`].$refs.basic.numberError = true
              return this.$noty.error('Необходимо ввести номер договора')
            }
            if (!this.newItem.branch) {
              this.$refs[`form_new`].$refs.staff.branchError = true
              return this.$noty.error('Необходимо выбрать магазин и юр. лицо')
            }
            if (!this.newItem.entity) {
              this.$refs[`form_new`].$refs.staff.entityError = true
              return this.$noty.error('Необходимо выбрать юр. лицо')
            }
          }
          await this.$apollo.mutate({
            mutation: require('../gql/CreateTreaty.graphql'),
            variables: {
              input: {
                entity: this.newItem?.entity?.id,
                branch: this.newItem?.branch?.id,
                supplier: this.getCurrentSupplierId,
                number: this.newItem?.number,
                date: this.newItem?.date,
                name: this.newItem?.name,
                delivery: {
                  delivery_days: this.newItem.delivery_days.map((obj) => obj.id) ?? [],
                  order_days: this.newItem.order_days.map((obj) => obj.id) ?? [],
                  delivery_weeks: this.newItem.delivery_weeks.map((obj) => obj.id) ?? [],
                  order_weeks: this.newItem.order_weeks.map((obj) => obj.id) ?? []
                },
                payment: {
                  payment_term: this.newItem.payment_term,
                  payment_due_date: +this.newItem.payment_due_date,
                  min_sum: +this.newItem.min_sum
                },
                is_active: this.newItem.is_active
              }
            }
          })
          if (isCopy) {
            this.$noty.show('Договор успешно дублирован')
          } else {
            this.$noty.show('Договор создан')
          }
          this.cancellationCreate()
          this.$emit('refetch')
        } catch (e) {
          this.$noty.error('Ошибка на сервере, попробуйте позже')
        }
      },
      cancellationCreate() {
        this.$emit('setOpen', false)
        this.newItem = {
          id: null,
          new: true,
          branch: null,
          entity: null,
          name: 'Новый договор',
          number: '',
          file: null,
          date: new Date(),
          address: '',
          payment_due_date: 0,
          delivery_days: [],
          delivery_weeks: [],
          order_days: [],
          order_weeks: [],
          min_sum: 0,
          payment_term: [
            {
              id: 'AFTER_FIX',
              name: 'Даты поставки'
            }
          ],
          is_active: true
        }
      },
      async updateContract(contract) {
        if (!contract.id) {
          this.newItem = contract
          return this.createContract()
        }
        try {
          this.updateItem = this.newItem
          if (!this.updateItem.name && !this.updateItem.number && !this.updateItem.branch) {
            this.$refs[`form${contract.id}`].$refs.basic.numberError = true
            this.$refs[`form${contract.id}`].$refs.basic.nameError = true
            this.$refs[`form${contract.id}`].$refs.staff.branchError = true
            return this.$noty.error('Заполните все необходимые поля')
          }
          if (!this.updateItem.name && !this.updateItem.number) {
            this.$refs[`form${contract.id}`].$refs.basic.numberError = true
            this.$refs[`form${contract.id}`].$refs.basic.nameError = true
            return this.$noty.error('Необходимо ввести наименование и номер договора')
          }
          if (!this.updateItem.name) {
            this.$refs[`form${contract.id}`].$refs.basic.nameError = true
            return this.$noty.error('Необходимо ввести наименование договора')
          }
          if (!this.updateItem.number) {
            this.$refs[`form${contract.id}`].$refs.basic.numberError = true
            return this.$noty.error('Необходимо ввести номер договора')
          }
          if (!this.updateItem.entity) {
            this.$refs[`form${contract.id}`].$refs.staff.entityError = true
            return this.$noty.error('Необходимо выбрать юр. лицо')
          }
          await this.$apollo.mutate({
            mutation: require('../gql/UpdateTreaty.graphql'),
            variables: {
              input: {
                id: this.updateItem.id,
                branch: this.updateItem?.branch,
                entity: this.updateItem.entity,
                supplier: this.getCurrentSupplierId,
                number: this.updateItem.number,
                date: this.updateItem.date,
                name: this.updateItem.name,
                delivery: {
                  delivery_days: this.updateItem.delivery_days?.map((obj) => obj.id) ?? [],
                  order_days: this.updateItem.order_days?.map((obj) => obj.id) ?? [],
                  delivery_weeks: this.updateItem.delivery_weeks?.map((obj) => obj.id) ?? [],
                  order_weeks: this.updateItem.order_weeks?.map((obj) => obj.id) ?? []
                },
                payment: {
                  treaty: this.updateItem.id,
                  payment_term: this.updateItem.payment_term,
                  payment_due_date: +this.updateItem.payment_due_date,
                  min_sum: +this.updateItem.min_sum
                },
                is_active: this.newItem.is_active
              }
            }
          })
          this.$noty.show('Данные успешно обновлены')
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка при обновлении договора')
        }
      },
      cancellationUpdate(id) {
        this.$refs[`form${id}`].$refs.basic.clearContract()
        this.$refs[`form${id}`].$refs.staff.clearContract()
        this.$refs[`form${id}`].$refs.pay.clearContract()
        this.$refs[`form${id}`].$refs.order.clearContract()
        this.$refs[`form${id}`].$refs.chartOrder.clearContract()
        this.$refs[`form${id}`].$refs.chartShipment.clearContract()
        this.updateItem = null
      },
      setContract(data) {
        if (data.id !== this.newItem.id) return
        for (const key of Object.keys(data)) {
          this.newItem[key] = data[key] ?? this.newItem[key]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contract {
    margin-bottom: 16px;
    width: 100%;
    padding: 22px;
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    .visible-info {
      display: flex;
      justify-content: space-between;
      &__left {
        .category__name {
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #313131;
        }
        .contract-info {
          display: flex;
          p {
            margin-bottom: 0;
            margin-right: 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #888888;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      &__right {
        display: flex;
        align-items: center;
      }
    }
  }

  ::v-deep() {
    .contracts {
      .save-btn {
        margin-top: -60px;
        float: right;
        button {
          width: 136px;
          justify-content: center;
        }
      }
      .contract-block {
        .add-contract {
          .btn-add {
            margin-top: 16px;
            margin-bottom: 16px;
            padding: 22px;
            display: flex;
            justify-content: center;
            color: #00a3ff;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            border-radius: 6px;
            border: 1px solid #00a3ff;
            width: 100%;
            img {
              margin-right: 12px;
            }
          }
        }
      }
    }

    .delete-button {
      img.not-collapsed {
        transform: rotate(180deg) !important;
      }
    }
  }

  // .contracts {
  //   .save-btn {
  //     margin-top: -60px;
  //     float: right;
  //     button {
  //       width: 136px;
  //       justify-content: center;
  //     }
  //   }
  //   .contract-block {
  //     .add-contract {
  //       .btn-add {
  //         margin-top: 16px;
  //         margin-bottom: 16px;
  //         padding: 22px;
  //         display: flex;
  //         justify-content: center;
  //         color: #00a3ff;
  //         font-style: normal;
  //         font-weight: 400;
  //         font-size: 16px;
  //         line-height: 20px;
  //         border-radius: 6px;
  //         border: 1px solid #00a3ff;
  //         width: 100%;
  //         img {
  //           margin-right: 12px;
  //         }
  //       }
  //     }
  //   }
  // }
  .dropdown-contract {
    margin-right: 16px;
    ::v-deep .dropdown-toggle {
      background: transparent !important;
    }

    .dots {
      display: flex;
      justify-content: center;
      transform: rotate(90deg);
    }
  }
  .delete-button {
    color: #e53835;
  }
  img {
    transition: all 0.3s ease-in-out;
  }
  // img.not-collapsed {
  //   transform: rotate(180deg) !important;
  // }
</style>
