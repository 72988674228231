var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract"
  }, [_c('div', {
    staticClass: "visible-info"
  }, [_c('div', {
    staticClass: "visible-info__left"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b', {
    staticClass: "category__name"
  }, [_vm._v(_vm._s(_vm.newItem.name))]), _c('img', {
    attrs: {
      "src": "/img/icons/delimiter.svg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "category__type"
  }, [_vm._v("Тип: " + _vm._s(_vm.mappingType[_vm.newItem.type]))]), _c('img', {
    attrs: {
      "src": "/img/icons/delimiter.svg",
      "alt": ""
    }
  }), _c('e-switch', {
    attrs: {
      "active_color": "#00CB91",
      "value": _vm.newItem.is_active ? 'all' : 'off'
    },
    on: {
      "input": function (val) {
        return val === 'all' ? _vm.newItem.is_active = true : _vm.newItem.is_active = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "contract-info"
  }, [_c('p', [_vm._v("№" + _vm._s(_vm.newItem.number))]), _c('p', [_vm._v(_vm._s(new Date(_vm.newItem.date).toLocaleDateString()))]), _c('p', [_vm._v(_vm._s(_vm.newItem.address))])])]), _c('div', {
    staticClass: "visible-info__right"
  }, [_c('b-dropdown', {
    staticClass: "dropdown-contract",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('img', {
          staticClass: "dots",
          attrs: {
            "src": "/img/icons/Dots.svg",
            "alt": "open-contract"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.deleteContract(_vm.newItem);
      }
    }
  }, [_c('div', {
    staticClass: "delete-button"
  }, [_vm._v("Удалить")])])], 1), _c('img', {
    class: {
      rotate: _vm.open
    },
    attrs: {
      "src": "/img/icons/arrow-contract.svg",
      "alt": "open-contract"
    },
    on: {
      "click": function ($event) {
        _vm.open = !_vm.open;
      }
    }
  })], 1)]), _c('b-collapse', {
    attrs: {
      "id": `contract-info${_vm.newItem.id}`
    },
    model: {
      value: _vm.open,
      callback: function ($$v) {
        _vm.open = $$v;
      },
      expression: "open"
    }
  }, [_c('form-contract', {
    ref: `form${_vm.newItem.id}`,
    attrs: {
      "item": _vm.newItem
    },
    on: {
      "set_contract": _vm.setContract
    }
  }), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updateContract(_vm.newItem);
      }
    }
  }, [_vm._v(" Сохранить ")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle",
      value: `contract-info${_vm.newItem.id}`,
      expression: "`contract-info${newItem.id}`"
    }],
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.cancellationUpdate(_vm.newItem.id);
      }
    }
  }, [_vm._v(" Отмена ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }